import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";

const Categories = () => {
  const categories = useSelector((state) => state.header.categories);

  const closePopup = () => {
    const categoryModel = document.querySelector('#Categories');
    if (categoryModel.classList.contains('open-it')) {
      categoryModel.classList.remove('open-it');
    }
    document.body.classList.remove('hidden-scroll');
    // Additional logic to close the popup
    console.log("Popup closed");
  };

  return (
    <div
      className="comman-model comman-side-popup side-category-model"
      id="Categories"
    >
      <div className="comman-popup-inner">
        <div className="top-model-header">
          {/* <a href="javascript:void(0);" className="backarrow open-popup" data-popup-id="LogIn"><i><img src="img/backarrow.svg" alt=""/></i></a> */}
          <div className="pop-top-title">
            <h2>More Categories</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <ul className="categorymenu-sidebar">
            {categories.slice(7).map((item, index) => (
              <li key={index}>
                <Link to={`/listing/byCategory/${item.slug}`} 
                  onClick={() => {
                    closePopup();
                    localStorage.setItem("categoryname", item.name);
                    localStorage.setItem("categoryslug", item.slug);
                  }}
                >
                  <i className="iconpt">
                    <img src={`${API_BASE_URL}${item.icon}`} alt={item.name} />
                  </i>
                  {item.name}
                </Link>
              </li>
            ))}
            {/* 
            <li>
              <a href="javascript:void(0);">
                <i className="iconpt">
                  <img src="../img/catimg02.jpg" alt="sowp" />
                </i>
                Home
              </a>
            </li>
            <li>
              <a href="#">
                <i className="iconpt">
                  <img src="../img/catimg03.jpg" alt="swop" />
                </i>
                Electronics
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i className="iconpt">
                  <img src="../img/catimg04.jpg" alt="swop" />
                </i>
                Fashion
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i className="iconpt">
                  <img src="../img/catimg05.jpg" alt="swop" />
                </i>
                kids &amp; Babies{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i className="iconpt">
                  <img src="../img/catimg06.jpg" alt="swop" />
                </i>
                Office
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i className="iconpt">
                  <img src="../img/catimg07.jpg" alt="swop" />
                </i>
                Art &amp; Designs
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
};

export default Categories;
